/* General Styling */
body {
    background-color: #f9fafb; /* Light background */
    font-family: 'Inter', sans-serif;
    margin: 0;
    padding: 0;
  }
  
  /* Main Container */
  .dashboard-container {
    min-height: 100vh;
    padding: 2rem;
    background-color: #f9fafb;
  }
  
  /* Header Section */
  .dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  .dashboard-header h1 {
    font-size: 2rem;
    font-weight: 700;
    color: #111827;
  }
  
  .add-button {
    display: inline-flex;
    align-items: center;
    padding: 0.5rem 1rem;
    background-color: #4f46e5;
    color: white;
    font-size: 0.875rem;
    font-weight: 500;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .add-button:hover {
    background-color: #4338ca;
  }
  
  .add-button svg {
    margin-right: 0.5rem;
    height: 1rem;
    width: 1rem;
  }
  
  /* Category Filter */
  .category-filter {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1.5rem;
    overflow-x: auto;
    padding-bottom: 0.5rem;
  }
  
  .filter-button {
    padding: 0.5rem 1rem;
    border-radius: 9999px;
    font-size: 0.875rem;
    font-weight: 500;
    background-color: #e5e7eb;
    color: #374151;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .filter-button:hover {
    background-color: #d1d5db;
  }
  
  .filter-button.active {
    background-color: #4f46e5;
    color: white;
  }
  
  /* Websites List */
  .websites-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  /* Website Card */
  .website-card {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.2s ease-in-out;
  }
  
  .website-card:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .website-icon {
    width: 3rem;
    height: 3rem;
    border-radius: 0.5rem;
    object-fit: cover;
    flex-shrink: 0;
  }
  
  .website-content {
    flex: 1;
    margin-left: 1rem;
  }
  
  .website-title {
    font-size: 1rem;
    font-weight: 600;
    color: #111827;
    margin: 0;
  }
  
  .website-category {
    font-size: 0.875rem;
    color: #6b7280;
    text-transform: capitalize;
  }
  
  .website-description {
    margin-top: 0.5rem;
    font-size: 0.875rem;
    color: #4b5563;
    line-height: 1.25rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .visit-button {
    margin-left: auto;
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: #4f46e5;
    background-color: #f3f4f6;
    border: none;
    transition: background-color 0.2s ease-in-out;
  }
  
  .visit-button:hover {
    background-color: #e5e7eb;
  }
  
  /* Empty State */
  .empty-state {
    text-align: center;
    padding: 4rem 0;
  }
  
  .empty-state svg {
    height: 3rem;
    width: 3rem;
    color: #9ca3af;
  }
  
  .empty-state h3 {
    font-size: 1.25rem;
    font-weight: 600;
    color: #111827;
    margin-top: 0.75rem;
  }
  
  .empty-state p {
    font-size: 0.875rem;
    color: #6b7280;
  }
  
  .empty-state button {
    margin-top: 1rem;
    display: inline-flex;
    align-items: center;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    font-weight: 500;
    background-color: #4f46e5;
    color: white;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .empty-state button:hover {
    background-color: #4338ca;
  }
  
  .empty-state button svg {
    margin-right: 0.5rem;
    height: 1rem;
    width: 1rem;
  }
  