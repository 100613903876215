/* Container */
.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f9f9f9;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    padding: 1rem;
    box-sizing: border-box;
  }
  
  /* Box */
  .login-box {
    background: #ffffff;
    padding: 2rem;
    width: 100%;
    max-width: 400px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }
  
  /* Title */
  .login-title {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 0.5rem;
  }
  
  .login-subtitle {
    font-size: 1rem;
    color: #666;
    margin-bottom: 1.5rem;
  }
  
  /* Form */
  .login-form {
    width: 100%;
  }
  
  .input-group {
    margin-bottom: 1rem;
  }
  
  .input-field {
    width: 90%;
    padding: 0.8rem;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f8f8f8;
    transition: border-color 0.3s ease;
  }
  
  .input-field:focus {
    border-color: #007aff;
    outline: none;
    background-color: #fff;
  }
  
  /* Error */
  .login-error {
    background-color: #ffebe8;
    color: #c00;
    border: 1px solid #c00;
    padding: 0.8rem;
    border-radius: 8px;
    margin-bottom: 1rem;
    font-size: 0.9rem;
  }
  
  /* Button */
  .submit-button {
    display: inline-block;
    width: 100%;
    padding: 0.8rem;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    background-color: #007aff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #005bb5;
  }
  
  .button-disabled {
    background-color: #9ec4f9;
    cursor: not-allowed;
  }
  
  /* Footer */
  .login-footer {
    margin-top: 1rem;
    font-size: 0.9rem;
    color: #666;
  }
  
  .signup-link {
    color: #007aff;
    font-weight: bold;
    text-decoration: none;
  }
  
  .signup-link:hover {
    text-decoration: underline;
  }
  