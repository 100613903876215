/* Base Styles */
.landing-page {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 0;
    padding: 0;
    color: #333;
    background-color: white;
    line-height: 1.6;
  }
  
  /* Hero Section */
  .hero {
    text-align: center;
    padding: 1rem 1rem;
  }
  
  .hero h1 {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #000;
  }
  
  .hero h2 {
    font-size: 1.5rem;
    font-weight: 300;
    margin-bottom: 1rem;
    color: #555;
  }
  
  .hero p {
    font-size: 1rem;
    margin-bottom: 2rem;
    color: #666;
  }
  
  .store-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 2rem;
  }
  
  .store-button {
    padding: 0.8rem 1.2rem;
    font-size: 0.9rem;
    border-radius: 0.5rem;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .playstore {
    background-color: #007aff;
    color: white;
  }
  
  .appstore {
    background-color: #333;
    color: white;
  }
  
  .store-button:hover {
    opacity: 0.8;
  }
  
  .preview-image img {
    max-width: 80%;
    margin-top: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Features Section */
  .features {
    padding: 2rem 1rem;
    text-align: center;
  }
  
  .features h2 {
    font-size: 2rem;
    margin-bottom: 2rem;
    color: #000;
  }
  
  .feature-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
  }
  
  .card {
    background-color: #fff;
    padding: 1.5rem;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .card h3 {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .card p {
    font-size: 0.95rem;
    color: #555;
  }
  
  /* Developer Section */
  .developer {
    padding: 3rem 1rem;
    background-color: #f4f4f4;
    text-align: center;
  }
  
  .developer h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #000;
  }
  
  .developer p {
    font-size: 1rem;
    margin-bottom: 2rem;
    color: #555;
  }
  
  .developer-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
  
  .primary-button {
    background-color: #007aff;
    color: white;
    padding: 0.8rem 1.5rem;
    font-size: 0.9rem;
    border-radius: 0.5rem;
    border: none;
    cursor: pointer;
  }
  
  .secondary-button {
    background-color: transparent;
    border: 1px solid #007aff;
    color: #007aff;
    padding: 0.8rem 1.5rem;
    font-size: 0.9rem;
    border-radius: 0.5rem;
    cursor: pointer;
  }
  
  .primary-button:hover, 
  .secondary-button:hover {
    opacity: 0.8;
  }
  